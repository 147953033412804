<template>
    <section>
        <div class="row mx-0 border-bottom py-1 mb-3 justify-center">
            <div class="col-auto text-general f-18">
                Pedidos realizados
            </div>
        </div>
        <div class="row mx-3">
            <div class="col px-0 d-flex">
                <div class="col-auto px-0">
                    <p class="text-general text-center f-15">Pedidos</p>
                    <div class="box-pedidos d-middle-center px-3 op-07">
                        <i class="icon-shopping-check f-18" />
                        <span class="f-15 ml-2">
                            {{ _.defaultTo(data.todosPedidos, 0) }}
                        </span>
                    </div>
                </div>
                <div class="col-auto px-0 ml-2">
                    <p class="text-general text-center f-15">Reclamos</p>
                    <div class="box-reclamos d-middle-center px-4 op-07">
                        <i class="icon-attention-alt f-18" />
                        <span class="f-15 ml-2">
                            {{ _.defaultTo(data.reclamosPedidos, 0) }}
                        </span>
                    </div>
                </div>
                <div class="col-auto px-0 ml-2">
                    <el-tooltip placement="bottom" content="Índice de reclamos" effect="light">
                        <div class="box-indice d-middle-center px-3 op-07 mt-4">
                            Indice
                            <span class="f-15 ml-2">
                                {{ porcentajeIndice }}
                            </span>
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="col pr-0 pl-2">
                <p class="text-general f-15 text-center">
                    Cancelaciones y rechazos
                </p>
                <el-tooltip placement="bottom" content="Cancelaciones del cliente" effect="light">
                    <div class="row mx-0">
                        <div class="col box-cancelaciones d-middle-center op-07">
                            <i class="icon-cedis" />
                            <span class="ml-2">{{ _.defaultTo(data.canceladoTiendaPedidos, 0) }}</span>
                        </div>
                        <div class="col box-cancelaciones d-middle-center op-07 mx-2">
                            <i class="icon-leechero-cap" />
                            <span class="ml-2">{{ _.defaultTo(data.canceladoClientePedidos, 0) }}</span>
                        </div>
                        <div class="col box-cancelaciones d-middle-center op-07">
                            <i class="icon-playlist-remove f-12" />
                            <span class="ml-2">{{ _.defaultTo(data.rechazadoPedidos, 0) }}</span>
                        </div>
                    </div>
                </el-tooltip>
            </div>
        </div>
        <p class="f-17 text-general mt-5 mx-3">
            Últimas liquidaciones del cliente
        </p>
        <echart :options="ultLiquidaciones" />
        <div class="row mx-0 py-3" />
        <div v-for="(pedido, i) in pedidos" :key="i" class="row mx-3 border br-4 px-2 py-2 mb-3">
            <img :src="pedido.logo_mini" class="br-4 obj-cover" width="40" height="40" />
            <div class="col">
                <div class="row mx-0 align-items-center">
                    <div class="col pl-0 text-general f-15">
                        {{ separadorNumero(pedido.valor_final) }}
                    </div>
                    <div class="col-3 pl-0 text-general f-15 f-600">
                        No. {{ pedido.id }}
                    </div>
                    <div class="col pl-0 text-general f-14">
                        <i class="icon-product f-14" />
                        {{ agregarSeparadoresNumero(pedido.count_productos) }}
                    </div>
                    <div class="col pl-0 text-general f-14 f-600 text-right">
                        <i v-if="pedido.verificado > 0" class="icon-truck-check f-15 text-green mr-1" />
                        {{ pedido.estado_liquidacion ? identificarEstado(pedido.estado_liquidacion) : '' }}
                    </div>
                </div>
                <div class="row mx-0 align-items-center">
                    <div class="col pl-0 text-general f-15 tres-puntos">
                        {{ pedido.propietario_nombre }}
                    </div>
                    <div class="col-3 pl-0 text-general f-15">
                        {{ formatearFecha(pedido.created_at) }}
                    </div>
                    <div class="col pl-0 text-general f-14">
                        <i :class="estadoIcono(pedido.estado).icon" />
                        {{ estadoIcono(pedido.estado).nombre }}
                    </div>
                    <div class="col pl-0 text-general f-14 text-right">
                        {{ pedido.fecha_liquidacion ? formatearFecha(pedido.fecha_liquidacion) : '' }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center my-3">
            <div class="paginar-button d-middle-center cr-pointer br-20 f-14" @click="fetchData(10)">
                Ver más 10
            </div>
            <div class="paginar-button d-middle-center cr-pointer br-20 ml-2 f-14" @click="fetchData(50)">
                Ver más 50
            </div>
        </div>
    </section>
</template>

<script>
import estadistica from '~/services/clientes'
import { mapGetters } from 'vuex'

let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')

const iconos = {

    1: {icon: 'icon-receipt', nombre: 'Creado'},
    2: {icon: 'icon-ok-circled', nombre: 'Confirmado'},
    201: {icon: 'icon-cart-arrow-down', nombre: 'Alistando'},
    202: {icon: 'icon-shopping-outline', nombre: 'Empacado'},
    3: {icon: 'icon-truck-fast-outline', nombre: 'Enviado'},
    4: {icon: 'icon-shopping', nombre: 'Finalizado'},
    110: {icon: 'icon-ok-circled', nombre: 'Ajustes Tienda en Proceso'},
    11: {icon: 'icon-ok-circled', nombre: 'Ajustes de Tienda'},
    12: {icon: 'icon-ok-circled', nombre: 'Ajustes confirmados'},
    13: {icon: 'icon-ok-circled', nombre: 'Confirmado con Cambios Cliente'},
    21: {icon: 'icon-ok-circled', nombre: 'Timeout Creado'},
    22: {icon: 'icon-ok-circled', nombre: 'Timeout Confirmado'},
    23: {icon: 'icon-ok-circled', nombre: 'Timeout Cambios Tendero'},
    24: {icon: 'icon-ok-circled', nombre: 'Timeout Cambios Cliente'},
    25: {icon: 'icon-shopping', nombre: 'Rechazado'},
    26: {icon: 'icon-shopping', nombre: 'Cancelado Tienda'},
    27: {icon: 'icon-shopping', nombre: 'Cancelado Cliente'},
    31: {icon: 'icon-shopping', nombre: 'Reclamo'},
    32: {icon: 'icon-shopping', nombre: 'Reclamo Finalizado'},
    33: {icon: 'icon-shopping', nombre: 'Soporte'},
    34: {icon: 'icon-shopping', nombre: 'Soporte Finalizado'},
}

export default {
    data(){
        return {
            data: {},
            pedidos: [],
            loading: false,
            grafica: [],
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
        }),
        porcentajeIndice(){
            if(_.isEmpty(this.data)) return 0
            return Math.round((this.data.reclamosPedidos * 100) / this.data.todosPedidos)
            
        },
        ultLiquidaciones(){
            return {
                legend: {
                    top: 20,
                    itemGap: 15,
                    data: ['Liquidación', 'Pedidos'],
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    padding: 10,
                },
                dataset:{
                    dimensions:['fecha', 'liquidacion', 'pedidos'],
                    source: this.grafica || []
                },
                xAxis: {
                    axisLabel: {
                        show: false,
                        margin: 20,
                        color: '#595959',
                        fontSize: 14,
                    },
                    type: 'category',
                    nameLocation: 'center',
                    nameGap: 50,
                    axisLine: {
                        lineStyle: {
                            type: 'dashed',
                            color: '#ECECEC',
                        },
                    },
                },
                yAxis: [
                    {
                        nameTextStyle: {
                            color: '#222',
                            fontSize: 14,
                        },
                        axisLabel: {
                            margin: 20,
                            color: '#595959',
                            fontSize: 14,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#ECECEC',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        type: 'value',
                        minInterval: 1,
                        nameGap: 20,
                    },
                    {
                        nameTextStyle: {
                            color: '#222',
                            fontSize: 14,
                        },
                        axisLabel: {
                            margin: 20,
                            color: '#595959',
                            fontSize: 14,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#ECECEC',
                            },
                        },
                        axisLine: {
                            show: false,
                        },
                        type: 'value',
                        minInterval: 1,
                        nameGap: 20,
                    },
                ],
                series: [
                    {
                        name: 'Liquidación',
                        yAxisIndex: 0,
                        type: 'bar',
                        barWidth: '20%',
                        yAxisIndex: 1,
                        symbol: 'emptyCircle',
                        smooth: 0.3,
                        symbolSize: 8,
                        showBackground: true,
                        itemStyle: {
                            color: '#DBDBDB',
                        },
                    },
                    {
                        name: 'Pedidos',
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 8,
                        lineStyle: {
                            color: '#612AD5'
                        },
                        itemStyle: {
                            borderWidth: 2,
                            color: '#612AD5',
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#612AD5',
                                borderColor: '#fff',
                            },
                        },
                    }
                ]
            }
        }
    },
    watch:{
        id_moneda(val, oldVal){
            if(oldVal == null) return
            setTimeout(() => {
                this.pedidos = []
                this.fetchData()
            }, 500);
        }
    },
    mounted(){
        setTimeout(() => {
            this.fetchData()
        }, 500);
    },
    methods: {
        async fetchData(paginate = 10){
            try {
                this.loading = true
                let params = {
                    id: this.$route.params.id_cliente,
                    offset: this.pedidos.length,
                    paginate,
                    moneda: this.id_moneda
                }
                const { data } = await estadistica.pedidosRealizados(params)
                this.data = data
                this.pedidos = [...this.pedidos, ...data.pedidos]
                this.grafica = [...this.grafica, ...data.grafica]
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        estadoIcono(estado){
            return iconos[estado] ?? iconos[1]
        },
        iconosLiquidaciones(state){
            switch (state){
            case 1:
                return 'ok-circled-outline';
            case 2: 
                return 'shopping-check';
            case 3: 
                return 'playlist-remove f-12 mr-1';
                
            }
        },
        identificarEstado(estado){
            switch(estado){
            case 1:
                return 'Liquidado';
            case 2:
                return 'Confirmado';
            case 3:
                return 'En observación';
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.box-pedidos{
    background-color: #AAFFC9;
    height: 41px;
    color: #000000;
    border-radius: 4px;
    /* width: 74px; */
}
.box-reclamos{
    background-color: #FFB8BB;
    height: 41px;
    color: #000000;
    border-radius: 4px;
    /* width: 74px; */
}

.box-indice{
    background-color: #FFFFFF;
    border: 1px solid #DBDBDB;
    color: #707070;
    height: 41px;
    color: #000000;
    border-radius: 4px;
}

.box-cancelaciones{
    background-color: #FFCD97;
    height: 41px;
    color: #000000;
    border-radius: 4px;
}

.paginar-button{
    width: 122px;
    height: 28px;
    color: #FFFFFF;
    background-color: #000000;
}
</style>